<template>
    <div class="datasoures">
        <div style="display: flex;">
                <Headbuttom></Headbuttom>
                <Tabheader 
                    @handRegister="handRegister" 
                    @handRegistertwo="handRegistertwo" 
                    @handRegistertb="handRegistertb" 
                    @handFromshow="handFromshow" 
                    :titlename="titlename">
                </Tabheader>
				<el-button type="primary" size="small" class="but" @click="handlerow3">
				    <!-- <i class="el-icon-check"></i> -->
				    {{this.$t('devtable.bmqxgl')}}
				</el-button>
            </div>
        <!-- <el-button>{{this.$t('devtable.bmqxgl')}}</el-button> -->
        <div class="fromshea" v-show="iShow">
            <div v-if="flagst==1">
                <el-form :inline="true" :model="formInline" size="small" class="demo-form-inline">
                    <el-form-item :label="this.$t('devtable.xm')">
                        <el-input v-model="formInline.name" :placeholder="this.$t('devtable.xm')"></el-input>
                    </el-form-item>
                    <el-form-item :label="this.$t('devtable.sjhm')">
                        <el-input v-model="formInline.mobile" :placeholder="this.$t('devtable.sjhm')"></el-input>
                    </el-form-item>
                   
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit1" size="small">{{this.$t('devtable.cx')}}</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <div v-if="flagst==2">
                <el-form :inline="true" :model="formInline" size="small" class="demo-form-inline">
                    <el-form-item :label="$t('devtable.sbxlh')">
                        <el-input v-model="serial_number" :placeholder="$t('devtable.sbxlhhwz')"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit2" size="small">{{$t('devtable.cx')}}</el-button>
                    </el-form-item>
                </el-form>
            </div>
        
        </div>
		
        <div v-if="flagst==1">
            <Tables 
                @handleSelectionChange="handleSelectionChange1" 
                @handlerow="handlerow" 
                @handlpages="handlpage1" 
                @handleSizeChange="handleSizeChange1"
                @handles="handlest" 
                :tableLabel="tableLabel" 
                :tableData="tableData" 
                :flags="flags" 
                :configs="configs" 
                :operation="operation"
                >
            </Tables>
        </div>
        <div v-if="flagst==2">
            <Tables 
                @handleSelectionChange="handleSelectionChange2" 
                @handleSizeChange="handleSizeChange2"
                @handlerow="handlerow2" 
                @handlpages="handlpage2" 
                @handles="handles" 
                :tableLabel="tableLabel2" 
                :tableData="tableData2" 
                :flags="flags" 
                :configs="configst" 
                :operation="operation"
            ></Tables>
        </div>
		<!-- <div v-if="flagst==3">
		    <Tables 
		        @handleSelectionChange="handleSelectionChange3" 
		        @handleSizeChange="handleSizeChange3"
		        @handlerow="handlerow3" 
		        @handlpages="handlpage3" 
		        @handles="handlesd" 
		        :tableLabel="tableLabel3" 
		        :tableData="tableData3" 
		        :flags="flags" 
		        :configs="configst" 
		        :operation="operation"
		    ></Tables>
		</div> -->
         
        <Authtabdiolg :authtabdiolgdata="authtabdiolgdata" :diolg="diolg" ref="authtabdiolg"></Authtabdiolg>

        <Rightsmadiolg ref="rightsmadiolg"></Rightsmadiolg>
        <Lefttsmadiolg ref="lefttsmadiolg" :shebfatherval="shebfatherval"></Lefttsmadiolg>
		<Depttsmadiolg ref="depttsmadiolg"></Depttsmadiolg>
    </div>
</template>
<script>
import Tables from '@/components/table'
import Tabheader from './components/authdiolg'
import Authtabdiolg from './components/authtabdiolg'
import Rightsmadiolg from './components/rightsmadiolg.vue'
import Lefttsmadiolg from './components/lefttsmadiolg.vue'
import Depttsmadiolg from './components/depttsmadiolg.vue'
import { formatDate } from '../../utils/date'
export default {
    components:{
        Tables,
        Tabheader,
        Authtabdiolg,
        Rightsmadiolg,
        Lefttsmadiolg,
		Depttsmadiolg
    },
    data(){
        return{
            serial_number:"",
            diolg:'1',
            authtabdiolgdata:{},
            flagst:1,
            iShow:false,
            formInline: {
                name: '',
                mobile: ''
            },
            input:'',
            centerDialogVisible: false,
            
            
            
            tableData: [],
            tableData2: [],
			tableData3: [],
            flags: {
                show: true, // 多选是否显示
                ishow: false // 序号是否显示
            },
            // 分页数据
            configs: {
                page_index:1,
                pagesize: 10,
                total: 200,
                loading: true // 加载状态
            },
            configst: {
                page_index:1,
                pagesize: 10,
                total: 200,
                loading: false // 加载状态
            },
            

            reftable:[],
            tongbuid:[],
            tongbuid2:[],
			tongbuid3:[],
            shebfatherval:{},
            UserInfo:''
        }
    },
    computed:{
        titlename:function(){
            return {
                name:this.$t('devtable.sh'),
                // titles:this.$t('devtable.plsh'),
                tlnames:this.$t('devtable.plsh')
            }
        },
        titlename:function(){
            return{
                name:this.$t('devtable.yg'),
                titles:this.$t('devtable.sb'),
                // tlnames:this.$t('devtable.tb'),
                tlnamest:'全部同步'
            }
            },
        operation:function() {
                return {
                    width:'170px',
                    data:[
                    {
                        name: this.$t('navLeft.qxgl'),
                        type: 'danger'
                    },
					
                    ]
                }
            },
        tableLabel:function(){
            return[
                {
                    prop: 'name',
                    label: this.$t('devtable.xm'),
                    align: 'center'
                },
                {
                    prop: 'mobile',
                    label: this.$t('devtable.sjhm'),
                    align: 'center'
                },
                {
                    prop: 'person_type',
                    label: this.$t('devtable.rylx'),
                    align: 'center'
                },
                // {
                    // prop: 'syn_time',
                    // label: this.$t('devtable.tbsj'),
                    // align: 'center'
                // },
                // {
                    // prop: 'is_syn',
                    // label: this.$t('devtable.tbzt'),
                    // align: 'center'
                // },
                {
                    prop: 'rlnumber',
                    label: this.$t('devtable.qxck'),
                    align: 'center'
                },
            ]
        },
		tableLabel3: function () {
		  return [
		    {
		      prop: "id",
		      label: this.$t("devtable.mjzjbh"),
		      align: "center",
		      width: this.width,
		    },
		    {
		      prop: "name",
		      label: this.$t("devtable.mjjzmc"),
		      align: "center",
		      width: this.width,
		    },
		    {
		      prop: "devices",
		      label: this.$t("devtable.lyh"),
		      align: "center",
		      width: 650,
		    },
			{
			    prop: 'rlnumber',
			    label: this.$t('devtable.qxck'),
			    align: 'center'
			},
		  ];
		},
        tableLabel2:function() {
            return[
                {
                    prop: 'serial_number',
                    label: this.$t('devtable.sbxlh'),
                    align: 'center'
                },
                {
                    prop: 'is_online',
                    label: this.$t('devtable.sbzt'),
                    align: 'center'
                },
                {
                    prop: 'support_face',
                    label: this.$t('devtable.sblx'),
                    align: 'center'
                },
                {
                    prop: 'name',
                    label: this.$t('devtable.azwz'),
                    align: 'center'
                },
                // {
                    // prop: 'syn_time',
                    // label: this.$t('devtable.tbsj'),
                    // align: 'center'
                // },
                // {
                    // prop: 'is_syn',
                    // label: this.$t('devtable.tbzt'),
                    // align: 'center'
                // },
                {
                    prop: 'rlnumber',
                    label: this.$t('devtable.qxck'),
                    align: 'center'
                },
            ]
        },
        
        most:function(){
            return {
                ry:this.$t('devtable.ry'),
                fk:this.$t('devtable.fk'),
                male:this.$t('devtable.male'),
                female:this.$t('devtable.female'),
                yzc:this.$t('devtable.yzc'),
                wzc:this.$t('devtable.wzc'),
                bfzc:this.$t('devtable.bfzc'),
                zcsb:this.$t('devtable.zcsb'),
            }
        }
    },
    mounted(){
        this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
        this.getStaffdata()
        this.getEquipment()
    },
    methods:{
		// 部门权限列表
		handControl(){
			this.flagst = 3
			console.log('77');
			let params = {
			  method: "co.group.device.list",
			  name: this.formInline.name,
			  agent_id: this.UserInfo.agent_id,
			  page_index: this.configs.page_index,
			  page_size:this.configs.pagesize,
			};
			this.$serve(params).then(res=>{
				res.data.data.forEach(element=>{
					element.rlnumber={
					    name:this.$t('devtable.qxck'),
					    but:true,
					    type:'primary'
					}
				})
				this.tableData3 = res.data.data;
				
			})
		},
        //人员员工列表
        getStaffdata(v,name,mobile){
            let data={
                method:'co.key.person.list',
                page_index:this.configs.page_index,
                page_size:this.configs.pagesize,
                name:name?name:"",
                manager_id:this.UserInfo.manager_id,
                agent_id:this.UserInfo.agent_id,
                mobile:mobile?mobile :""
            }
            this.$serve(data).then(res=>{
                console.log(`56`,res)
                let data = res.data.data
                data.forEach(element => {
                    element.syn_time = element.syn_time? formatDate(new Date(element.syn_time*1000), 'yyyy-MM-dd'):""
                    // element.status={
                    //     name: "员工",
                    //     state:true,
                    //     type:'primary'
                    // },
                    if(element.person_type==0){
                        element.person_type={
                            name:this.most.ry,
                            state:true,
                            type:'primary'
                        }
                    }else{
                        element.person_type={
                            name:this.most.fk,
                            state:true,
                            type:'primary'
                        }
                    }
                    element.rlnumber={
                        name:this.$t('devtable.qxck'),
                        but:true,
                        type:'primary'
                    }
                    if(element.is_syn == 0){
                        element.is_syn={
                            name:this.$t('devtable.wtb'),
                            state:true,
                            type:'danger'
                        }
                    }else if(element.is_syn === 1){
                        element.is_syn={
                            name:this.$t('devtable.lwtb'),
                            state:true,
                            type:'warning'
                        }
                    }else if(element.is_syn===2){
                        element.is_syn={
                            name:this.$t('devtable.kwtb'),
                            state:true,
                            type:'warning'
                        }
                    }else if(element.is_syn==3){
                        element.is_syn={
                            name:this.$t('devtable.kmmmwtb'),
                            state:true,
                            type:'warning'
                        }
                    }else{
                        element.is_syn={
                            name:this.$t('devtable.ytb'),
                            state:true,
                            type:'success'
                        }
                    }
                });
                this.tableData = data
                this.configs.total=res.data.count
                this.configs.loading=false
            })
        },
        // 人员权限查看
        handlest(v){
            console.log(`5`,v)
            this.diolg = '1'
            this.$refs.authtabdiolg.authtabdiolgdata = v
            this.$refs.authtabdiolg.handserveList()
            this.$refs.authtabdiolg.centerDialogVisible =true
        },
		handlesd(v){
            this.diolg = '3'
            this.$refs.authtabdiolg.authtabdiolgdata = v
			console.log(v);
			this.$refs.authtabdiolg.prantval = v
            this.$refs.authtabdiolg.handeControlList()
            this.$refs.authtabdiolg.centerDialogVisible =true
        },
        //人员界面权限管理
        handlerow(val){
            console.log(`66`,val)
         this.$refs.rightsmadiolg.prantval = val
         this.$refs.rightsmadiolg.handserveLeft()
         this.$refs.rightsmadiolg.handserveRight()
         this.$refs.rightsmadiolg.centerDialogVisible=true
        },
        // 人员的界面的分页
        handlpage1(v){
            this.configs.page_index=v
            this.getStaffdata()
			
        },
        handleSizeChange1(val){
            this.configs.page_index=1
            this.configs.pagesize=val
            this.getStaffdata()
        },
        // 人员选择框得到同步id
        handleSelectionChange1(val){
            val.forEach(element => {
                if(this.tongbuid.indexOf(element.id)==-1){
                    this.tongbuid.push(element.id)
                } 
            });
        },

        //设备列表
        getEquipment(v,serial_number){
            let data={
                method:'co.key.device.list',
                page_index:this.configst.page_index,
                page_size:this.configst.pagesize,
                manager_id:this.UserInfo.manager_id,
                serial_number:serial_number?serial_number:'',
                agent_id:this.UserInfo.agent_id,
            }
            this.$serve(data).then(res=>{
                let data = res.data.data
                
                data.forEach(element => {
                
                    element.syn_time = element.syn_time? formatDate(new Date(element.syn_time*1000), 'yyyy-MM-dd'):""
                    element.rlnumber={
                        name:this.$t('devtable.qxck'),
                        but:true,
                        type:'primary'
                    }
                    if(element.is_online==0){
                        element.is_online={
                            name:this.$t('devtable.bzx'),
                            state:true,
                            type:'primary'
                        }
                    }else{
                        element.is_online={
                            name:this.$t('devtable.zx'),
                            state:true,
                            type:'primary'
                        }
                    }
                    if(element.support_face == 0){
                        element.support_face={
                            name:this.$t('devtable.ymj'),
                            state:true,
                            type:'primary'
                        }
                    }else{
                        element.support_face={
                            name:this.$t('devtable.rlj'),
                            state:true,
                            type:'primary'
                        }
                    }
                    if(element.is_syn == 0){
                        element.is_syn={
                            name:this.$t('devtable.wtb'),
                            state:true,
                            type:'danger'
                        }
                    }else if(element.is_syn === 1){
                        element.is_syn={
                            name:this.$t('devtable.lwtb'),
                            state:true,
                            type:'warning'
                        }
                    }else if(element.is_syn===2){
                        element.is_syn={
                            name:this.$t('devtable.kwtb'),
                            state:true,
                            type:'warning'
                        }
                    }else if(element.is_syn==3){
                        element.is_syn={
                            name:this.$t('devtable.kmmmwtb'),
                            state:true,
                            type:'warning'
                        }
                    }else{
                        element.is_syn={
                            name:this.$t('devtable.ytb'),
                            state:true,
                            type:'success'
                        }
                    }
                });
                this.tableData2 =data
                this.configst.total=res.data.count
                this.configst.loading=false
            })
        },

        // 设备选择框得到同步id 
        handleSelectionChange2(val){
            val.forEach(element => {
                if(this.tongbuid2.indexOf(element.id)==-1){
                    this.tongbuid2.push(element.id)
                } 
            });
        },
		handleSelectionChange3(val){
            val.forEach(element => {
                if(this.tongbuid3.indexOf(element.id)==-1){
                    this.tongbuid3.push(element.id)
                } 
            });
        },
        // 设备 权限查看
        handles(v){
            this.diolg = '2'
            this.$refs.authtabdiolg.authtabdiolgdata = v
            this.$refs.authtabdiolg.handequipmentList()
            this.$refs.authtabdiolg.centerDialogVisible =true
        },
		// 部门权限管理
		handlerow3(val){
		    this.diolg = '3'	    
		    // this.$refs.rightsmadiolg.handserveRight()
		    // this.$refs.rightsmadiolg.centerDialogVisible=true
		    
		    this.$refs.depttsmadiolg.prantval = val
		    this.$refs.depttsmadiolg.getdeplist()
		    this.$refs.depttsmadiolg.handserveRight()
		    this.$refs.depttsmadiolg.centerDialogVisible=true
		},
        // 设备 界面权限管理
        handlerow2(val){
            
            
            // this.$refs.rightsmadiolg.handserveRight()
            // this.$refs.rightsmadiolg.centerDialogVisible=true
            
      this.$refs.lefttsmadiolg.prantval = val
      this.$refs.lefttsmadiolg.handserveLeft()
      this.$refs.lefttsmadiolg.handserveRight()
      this.$refs.lefttsmadiolg.centerDialogVisible=true
        },
        // 设备 分页
        handlpage2(v){
            this.configst.page_index=v
            this.getEquipment()
        },
        handleSizeChange2(val){
            this.configst.page_index=1
            this.configst.pagesize=val
            this.getEquipment()
        },
        handRegister(){
            this.flagst=1
        },
        handRegistertwo(){
            
            this.flagst=2
        },
        
        // 同步
        handRegistertb(){
            if(this.flagst==1){
                this.configs.loading= true
                let data={
                    method:'co.person.key.syn',
                    id:this.tongbuid.toString(),
                    agent_id:this.UserInfo.agent_id,
                    manager_id:this.UserInfo.manager_id
                }
                this.$serve(data).then(res=>{
                    if(res.data.code==0){
                         this.configs.loading= false
                        this.$message({
                            message:this.$t('devtable.tbcg'),
                            type: 'success'
                        });
                    }else{
                        this.configs.loading= false
                        this.$message({
                            message: this.$t('devtable.tbsb'),
                            type: 'error'
                        });
                    }
                })
            }else{
                this.configst.loading= true
                let data={
                    method:'co.person.key.syn',
                    id:this.tongbuid2.toString(),
                    agent_id:this.UserInfo.agent_id,
                    manager_id:this.UserInfo.manager_id
                }
                this.$serve(data).then(res=>{
                    if(res.data.code==0){
                         this.configst.loading= false
                        this.$message({
                            message: this.$t('devtable.tbcg'),
                            type: 'success'
                        });
                    }else{
                        this.configst.loading= false
                        this.$message({
                            message: this.$t('devtable.tbsb'),
                            type: 'error'
                        });
                    }
                })
            }
        },
        handFromshow(){
            this.iShow = !this.iShow 
        },
        onSubmit1() {
			this.configs.page_index = 1
			this.configst.page_index = 1
            this.getStaffdata("",this.formInline.name,this.formInline.mobile)
        },
        onSubmit2(){
            this.getEquipment('',this.serial_number) 
        },
        
        //设备权限管理
        getshebList(v){
            this.shebfatherval = v
            let data={
                method:'co.key.check.device.list',
                page_index:1,
                page_size:10,
                name:v.name,
                agent_id:this.UserInfo.agent_id,
                manager_id:this.UserInfo.manager_id,
                device_id:'',
                dept_id:''
            }
            this.$serve(data).then(res=>{
                this.$refs.authtabdiolg.tableData=res.data.data
                this.$refs.lefttsmadiolg.tableData1 = res.data.data
            })
        },
        //人员权限管理列表(权限管理左列表)
        getsatList(id){
            console.log(`78`,id)
            let data={
                method:'co.key.manage.person.list',
                page_index:1,
                page_size:10,
                serial_number:'',
                agent_id:this.UserInfo.agent_id,
                manager_id:this.UserInfo.manager_id,
                person_id:id,
                dept_id:''
            }
            this.$serve(data).then(res=>{
                console.log(`98`,res)
                this.$refs.authtabdiolg.tableData=res.data.data
                this.$refs.rightsmadiolg.tableData1 = res.data.data
            })
        },
        
        
        // handles(v){

        //     this.$refs.authtabdiolg.centerDialogVisible =true
        // }
    }
}
</script>
<style lang="less" scoped>
.datasoures{
    padding: 20px;
    background-color: #fff;
    .fromshea{
        margin-top:20px;
        padding: 20px;
    }
    .shear{
        display: flex;
        span{
            
        }
    }
}
.name{
    flex: 2;
    line-height: 30px;
}
.tbut{
    margin-left: 20px;
}
</style>