<template>
  <div>
    <el-dialog
      :title="$t('navLeft.qxgl')"
      :visible.sync="centerDialogVisible"
      width="980px"
      append-to-body
      center
    >
      <div class="cont_row">
        <p>{{ $t("devtable.bmqxgl") }}</p>
        <div class="cont_paly">
          <el-col>
           <!-- <el-col :span="3"
              ><span
                style="
                  line-height: 32px;
                  display: inline-block;
                  text-align: center;
                "
                >{{ $t("devtable.bm") }}</span
              ></el-col
            > -->
            <!-- <el-col :span="4">
              <Select @handleCheckChange="handleCheckChange"></Select>
            </el-col> -->
          </el-col>
          <el-col :span="5" style="margin-top: 15px; margin-left: 42px">
            <el-input v-model="name" size="small"></el-input>
          </el-col>

          <el-col :span="2" style="margin-top: 15px; margin-left: 20px">
            <el-button size="small" @click="handQuesy" type="primary">{{
              $t("devtable.cx")
            }}</el-button>
          </el-col>

         <el-col :span="5" style="margin-top: 15px; margin-left: 42px">
           <el-input v-model="name" size="small"></el-input>
         </el-col>
         
         <el-col :span="2" style="margin-top: 15px; margin-left: 20px">
           <el-button size="small" @click="handQuesy" type="primary">{{
             $t("devtable.cx")
           }}</el-button>
         </el-col>
        </div>

        <div style="padding: 20px; height: auto">
          <el-col :span="10">
            <el-table
              border
              height="250px"
              ref="multipleTable"
              :data="tableData1"
              tooltip-effect="dark"
              style="width: 100%"
              
              @selection-change="handleSelectionChange1"
            >
              <el-table-column type="selection"> </el-table-column>

              <el-table-column
                prop="name"
                :label="$t('devtable.bmmc')"
                align="center"
              >
                <template slot-scope="scope">{{ scope.row.name }}</template>
              </el-table-column>
              <!-- <el-table-column
                prop="number"
                :label="$t('devtable.gh')"
                align="center"
								width="120"
              >
              </el-table-column> -->
              <!-- <el-table-column
                prop="card_no"
                :label="$t('devtable.kh')"
                align="center"
                show-overflow-tooltip
				width="120"
              >
              </el-table-column> -->
            </el-table>
            <page @handleSizeChange="handleSizeChange" :pageSize="leftpage_size" :total="leftpagetotal"></page>
            
          </el-col>

          <!-- <el-col :span="4" style="line-height: 250px">
            <el-button
              type="primary"
              :disabled="lfflag"
              @click="handLeft"
              style="margin-left: 4px; margin-right: 4px"
              ><i class="el-icon-arrow-left"></i
            ></el-button>
            <el-button type="primary" :disabled="riflag" @click="handRight"
              ><i class="el-icon-arrow-right"></i
            ></el-button>
          </el-col>
 -->
			<!-- <div style="width: 100px;height: 10px;"></div> -->
          <el-col :span="10">
            <el-table
              border
              height="250px"
              ref="multipleTable"
              :data="tableData2"
			  :row-key="getRowKey"
			  @row-click="onSelectOp"
              tooltip-effect="dark"
              style="width: 100%"
              @selection-change="selectItem"
            >
              <el-table-column type="selection"> </el-table-column>
			  <el-table-column :label="$t('navLeft.mjjz')" prop="name" align="center" />
			  <el-table-column :label="$t('devtable.lyh')" prop="devices" align="center" />
              <!-- <el-table-column prop="name" :label="$t('navLeft.mjjz')" width="90" align="center">
                <template slot-scope="scope">{{ scope.row.name }}</template>
              </el-table-column>
              <el-table-column prop="devices" :label="$t('devtable.lyh')" width="240" align="center">
              </el-table-column> -->
            </el-table>
            <page @handleSizeChange="handleSizeChange2" :pageSize="rightpage_size" :total="rightpagetotal"></page>
          </el-col>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="getPageInter">{{
          $t("tablename.qd")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Select from "@/components/selectree/select";
// import Table from "@/components/table";
import page from "@/components/page/page.vue"
export default {
  components: {
    Select,
    // Table,
    page
    // Pagination
  },
  props: {
    shebfatherval: Object,
  },
  data() {
    return {
		deviceName:'',
      leftpagetotal:null,
      rightpagetotal:null,
        pagetotal:null,
      centerDialogVisible: false,
      name: "",
      tableData1: [
        {
          name: "",
          serial_number: "",
          unlock_state: "",
        },
      ],

      tableData2: [],
      multipleSelection1: [],
      multipleSelection2: [],
      riflag: true,
      lfflag: true,

      prantval: {},
      dept_id: "",
      handservePreservationid: [],
      deleltid: [],
      optionspro: [],
      period_no: "All",
      vationid:[] ,//添加权限splice后的数组
      deltionid:[],//删除权限splice后的数组
      
      leftpage_size:100,
      leftpage_index:1,
      righpage_index:1,
      rightpage_size:100
    };
  },
  mounted() {
    this.UserInfo = JSON.parse(window.localStorage.getItem("user"));
    this.handperiod();
  },
  methods: {
	  getRowKey(row) {
	    return row.id
	  },
	  
	  selectItem(rows) {
	    if (rows.length > 1) {
	      const newRows = rows.filter((it, index) => {
	        if (index == rows.length - 1) {
	          this.$refs.multipleTable.toggleRowSelection(it, true);
	          return true;
	        } else {
	          this.$refs.multipleTable.toggleRowSelection(it, false);
	          return false;
	        }
	      });
	      this.multipleSelection2 = newRows;
	    } else {
	      this.multipleSelection2 = rows;
	    }
		console.log(this.multipleSelection2);
	  },
	  
	  onSelectOp(row) {
	    this.$refs.multipleTable.clearSelection();
	    this.$refs.multipleTable.toggleRowSelection(row, true);
	    this.multipleSelection2 = [];
	    this.multipleSelection2.push(row);
	  },
	  
	  
    // 时间段
    handperiod() {
      let data = {
        method: "co.period.droplist",
        agent_id: this.UserInfo.agent_id,
      };
      this.$serve(data).then((res) => {
        this.optionspro = res.data.data;
        console.log(`5567`, this.optionspro);
      });
    },
    // 设备 权限管理 左边 分页
    handleSizeChange(val){
      this.leftpage_index= val
      // this.handserveLeft()
	  this.getdeplist()
    },

    // 设备 权限管理 左边

    handserveLeft(name, dept_id) {
      // console.log(`6`,this.prantval)
      // return
      console.log('ee11')
      let data = {
        method: "co.group.device.left.list",
        page_index: this.leftpage_index,
        page_size: this.leftpage_size,
        name: name ? name : "",
        agent_id: this.UserInfo.agent_id,
        manager_id: this.UserInfo.manager_id,
        group_id: this.prantval.id,
        dept_id: dept_id,
      };
      this.$serve(data).then((res) => {
        console.log(`909`,res.data.data)
        this.tableData1 = res.data.data;
        this.leftpagetotal = res.data.count
      });
    },

    //  设备 权限管理 右边分页
    handleSizeChange2(val){
      this.righpage_index= val
      this.handserveRight()
    },
    // 设备 权限管理 右边
    handserveRight(name, dept_id) {
      let data = {
		method: "co.group.device.list",
		name: this.deviceName,
		agent_id: this.UserInfo.agent_id,
		page_index: this.righpage_index,
        page_size: this.rightpage_size,
      };
      this.$serve(data).then((res) => {
          // console.log(`9095`,res.data.count)
          this.pagetotal = res.data.count
        this.tableData2 = res.data.data;
        this.rightpagetotal = res.data.count
      });
    },

    //查询
    handQuesy() {
		this.getdeplist()
      // this.handserveLeft(this.name, this.dept_id);
      this.handserveRight(this.name, this.dept_id);
    },

    // 定时器1
    getPageInter(map) {
      this.loading = this.$loading({
        //加载层
        lock: true,
        text: "正在保存中,请勿关闭",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.intertimer = setInterval(() => {
        this.intervalData(map); //每三秒调用一次方法
      }, 2000);
    },
    //定时器2
    intervalData(map) {
        console.log(`2`,this.multipleSelection1)
		console.log(`3`,this.multipleSelection2)
      if (this.multipleSelection1.length<=0 && this.multipleSelection2.length<=0) {
          
        clearInterval(this.intertimer); //关闭定时器
        this.loading.close(); //关闭弹窗    
        this.$message({
            message: this.$t("devtable.bcsbwxz"),
            type: "error",
        });
        
        // this.$parent.getEquipment();
        //  this.$parent.getStaffdata()
        this.centerDialogVisible = false;
      } else {
          this.multipleSelection1.forEach(item=>{
			  this.vationid.push(item.id)
		  })
        this.handserveprmast(map); //数据渲染
      }
			// this.handserveprmast(map); //数据渲染
    },
    //保存

    handserveprmast() {
        // console.log(this.vationid)
        // console.log(this.deltionid)
        
      let data = {
        method: "co.group.device.key.save",
        dept_id: this.vationid.toString(),
        manager_id: this.UserInfo.manager_id,
        period_no:'',
        group_id: this.multipleSelection2[0].id,
		agent_id: this.UserInfo.agent_id,
      };
      // console.log(data)
      this.$serve(data).then((res) => {
        if (res.data.code == 0) {
			this.$message({
			  message: this.$t("devtable.bccg"),
			  type: "success",
			});
        } else {
          this.$message({
            message: this.$t("devtable.bcsb"),
            type: "error",
          });
		    //关闭定时器
        }
		this.loading.close();
		clearInterval(this.intertimer);
      });
      // this.deleltid = [];
      this.period_no = 'All'
    //   this.$parent.getStaffdata()
    //   this.centerDialogVisible = false;
    },
	openDeptList(obj,list){
		list.push(obj)
		if(obj.children){
			obj.children.forEach(item=>{
				list.push(item)
				if(item.children){
					this.openDeptList2(item.children,list)
				}
			})
		}
	},
	openDeptList2(arr,list){
		arr.forEach(item=>{
			list.push(item)
			if(item.children){
				this.openDeptList2(item.children,list)
			}
		})
	},
		// 获取部门列表
		getdeplist() {
		    let data = {
		        method: "co.dept.list",
		        agent_id:this.UserInfo.agent_id,
		        manager_id: this.UserInfo.manager_id,
		    };
		    this.$serve(data).then((res) => {
		        var result = [];
		        if (res.data.data != null) result.push(this.getFirstObj(res.data.data));
		        console.log(this.getFirstObj(res.data.data));
				if(this.getFirstObj(res.data.data).children){
					this.getFirstObj(res.data.data).children.forEach(item=>{
						if(item.children){
							this.openDeptList2(item.children,result)
						}
						result.push(item)
					})
				}else{
					result.push(item)
				}
				// this.openDeptList(this.getFirstObj(res.data.data),result)
					this.tableData1 = result
				
				console.log(this.tableData1);
		    });
		},
		getFirstObj(obj) {
		    for (var key in obj) {
		        return obj[key];
		    }
		},
    handleCheckChange(val) {
      this.dept_id = val.toString();
    },

    handleSelectionChange1(val) {
      this.multipleSelection1 = val;
      if (this.multipleSelection1.length > 0) {
        this.riflag = false;
      } else {
        this.riflag = true;
      }
	  console.log(this.multipleSelection1);
    },
    handleSelectionChange2(val) {
		
      this.multipleSelection2 = val;
	  console.log(this.multipleSelection2);
	  if(this.multipleSelection2.length==1){
		  
	  }
      if (this.multipleSelection2.length > 0) {
        this.lfflag = false;
      }
    },
	checkSelectable(row){
		console.log(row);
		// if(this.multipleSelection2.length==1){
		// 	return false
		// }
		// if(this.multipleSelection2.length==0){
		// 	return true
		// }
		
	},
    handLeft() {
      this.multipleSelection2.forEach((el) => {
        let index = this.tableData2.indexOf(el);
        this.deleltid.push(el.id);
        this.tableData2.splice(index, 1);
        this.tableData1.push(el);
      });
      this.multipleSelection2 = [];
      this.lfflag = false;
    },
    handRight() {
      this.multipleSelection1.forEach((element) => {
        this.handservePreservationid.push(element.id);
        let index = this.tableData1.indexOf(element);
        this.tableData1.splice(index, 1);
        this.tableData2.push(element);
      });
      this.multipleSelection1 = [];
      this.riflag = true;
    },
    // 保存
    // centerDialog(){
    //     console.log(this.tableData2)
    //     return
    //     let data={
    //         method:'co.key.manage.device.add',
    //         id:'',
    //         manager_id:this.UserInfo.manager_id,
    //         period_no:'',
    //         device_id:this.shebfatherval.id,
    //         del_id:this.deleltid?this.deleltid.toString():''
    //     }
    //     this.$serve(data).then(res=>{

    //     })
    // }
  },
};
</script>

<style lang="less" scoped>
.el-dialog__body {
  .cont_row {
    width: 100%;
    height: 420px;
    border: 1px solid #cccccc;
    position: relative;
    p {
      width: 60px;
      height: 30px;
      position: absolute;
      left: 20px;
      top: -13px;
      background-color: #fff;
      text-align: center;
    }
    .cont_paly {
      padding: 30px;
      height: 60px;
    }
  }
}
</style>

